import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { catchError, map, Observable } from 'rxjs';
import { AppMessageService } from './app-message.service';

export enum CallableNames {
  connectEmails = 'connectEmails', // tmp
  mapNumberSeries = 'mapNumberSeries', // tmp
  updateInvoiceCode = 'updateInvoiceCode', // tmp
  updateTaxReceiptCode = 'updateTaxReceiptCode', // tmp
  callablesStatisticsLecturerGetByMonth = 'callablesStatisticsLecturerGetByMonth',
  callablesOrganizationsUpdateInfo = 'callablesOrganizationsUpdateInfo',
  callablesOrganizationsSetMissingData = 'callablesOrganizationsSetMissingData',
  callablesOrganizationsGet = 'callablesOrganizationsGet',
  callablesOrganizationsMembersUpdate = 'callablesOrganizationsMembersUpdate',
  callablesOrganizationsMembersAdd = 'callablesOrganizationsMembersAdd',
  callablesOrganizationsMembersRemove = 'callablesOrganizationsMembersRemove',
  callablesOrganizationsMembersInviteUnregistered = 'callablesOrganizationsMembersInviteUnregistered',
  callablesPaymentsGetInstructions = 'callablesPaymentsGetInstructions',
  callablesLecturersAttendanceGet = 'callablesLecturersAttendanceGet',
  callablesSessionGroupsGetForSessLectAttendance = 'callablesSessionGroupsGetForSessLectAttendance',
  callablesSessionGroupsGetUsersAttendance = 'callablesSessionGroupsGetUsersAttendance',
  callablesReservationsSetWaiting = 'callablesReservationsSetWaiting',
  callablesReservationsSetExpiration = 'callablesReservationsSetExpiration',
  callablesReservationsGetForTableByOrg = 'callablesReservationsGetForTableByOrg',
  callablesReservationsConfirm = 'callablesReservationsConfirm',
  callablesReservationsCancel = 'callablesReservationsCancel',
  callablesReservationsApproveSubmitted = 'callablesReservationsApproveSubmitted',
  callablesReservationsCreate = 'callablesReservationsCreate',
  callablesReservationsGetUserSummaries = 'callablesReservationsGetUserSummaries',
  callablesReservationsApproveWaiting = 'callablesReservationsApproveWaiting',
  callablesShopItemsUpdateReservationState = 'callablesShopItemsUpdateReservationState',
  callablesShopItemsCreate = 'callablesShopItemsCreate',
  callablesShopItemsUpdateSection = 'callablesShopItemsUpdateSection',
  callablesShopItemsGetPresentationInfo = 'callablesShopItemsGetPresentationInfo',
  callablesShopItemsUpdateState = 'callablesShopItemsUpdateState',
  callablesShopItemsGet = 'callablesShopItemsGet',
  callablesShopItemsArchive = 'callablesShopItemsArchive',
  callablesSessionLecturersUpdate = 'callablesSessionLecturersUpdate',
  callablesSessionLecturersCreate = 'callablesSessionLecturersCreate',
  callablesSessionLecturersPostAttendance = 'callablesSessionLecturersPostAttendance',
  callablesSessionLecturersDelete = 'callablesSessionLecturersDelete',
  callablesTransfersRecordByCash = 'callablesTransfersRecordByCash',
  callablesTransfersCreate = 'callablesTransfersCreate',
  callablesTransfersGetPayments = 'callablesTransfersGetPayments',
  callablesTransfersGetUserOrgUnpaid = 'callablesTransfersGetUserOrgUnpaid',
  callablesSessionAttendanceAddAttendee = 'callablesSessionAttendanceAddAttendee',
  callablesSessionAttendanceChangeState = 'callablesSessionAttendanceChangeState',
  callablesMagicCodesVerify = 'callablesMagicCodesVerify',
  callablesSessionsGetMonthList = 'callablesSessionsGetMonthList',
  callablesUserInvitationsPostAccept = 'callablesUserInvitationsPostAccept',
  callablesUserInvitationsPost = 'callablesUserInvitationsPost',
  callablesUserInvitationsPostReject = 'callablesUserInvitationsPostReject',
  callablesUserInvitationsPostOpened = 'callablesUserInvitationsPostOpened',
  callablesUserInvitationsGet = 'callablesUserInvitationsGet',
  callablesLocationsUpdate = 'callablesLocationsUpdate',
  callablesLocationsPost = 'callablesLocationsPost',
  callablesLocationsGet = 'callablesLocationsGet',
  callablesLocationsArchive = 'callablesLocationsArchive',
  callablesUsersWhisper = 'callablesUsersWhisper',
  callablesUsersGetExistRegistered = 'callablesUsersGetExistRegistered',
  callablesUsersGet = 'callablesUsersGet',
  callablesUsersPostRegistration = 'callablesUsersPostRegistration',
  callablesUsersCoordinatorGet = 'callablesUsersCoordinatorGet',
  callablesUsersAdminPostRegistrationWithEvent = 'callablesUsersAdminPostRegistrationWithEvent',
  callablesUsersLecturerUpdateProfile = 'callablesUsersLecturerUpdateProfile',
  callablesUsersLecturerGet = 'callablesUsersLecturerGet',
  callablesUsersChildrenGet = 'callablesUsersChildrenGet',
  callablesApiCreate = 'callablesApiCreate',
  callablesApiGetByOrgId = 'callablesApiGetByOrgId',
  callablesApiTest = 'callablesApiTest',
  callablesOrdersSetExpiration = 'callablesOrdersSetExpiration',
  callablesOrdersGetByUser = 'callablesOrdersGetByUser',
  callablesOrdersCancel = 'callablesOrdersCancel',
  callablesOrdersCreate = 'callablesOrdersCreate',
  callablesExportJobReport = 'callablesExportJobReport',
  callablesEventsSetPublishableMissingInfo = 'callablesEventsSetPublishableMissingInfo',
  callablesEventsDuplicate = 'callablesEventsDuplicate',
  callablesEventsPost = 'callablesEventsPost',
  callablesEventsUpdateSection = 'callablesEventsUpdateSection',
  callablesEventsSendTestReservationConfirmEmail = 'callablesEventsSendTestReservationConfirmEmail',
  callablesEventsUpdateState = 'callablesEventsUpdateState',
  callablesEventsGet = 'callablesEventsGet',
  callablesEventsArchive = 'callablesEventsArchive',
  callablesEventsGetUniqueUrlId = 'callablesEventsGetUniqueUrlId',
  callablesIcsGenerate = 'callablesIcsGenerate',
  callablesCollectedUserDataCreate = 'callablesCollectedUserDataCreate',
  callablesShopItemsDuplicate = 'callablesShopItemsDuplicate',
  callablesTransfersGet = 'callablesTransfersGet',
  callablesDiscountsTemplatesCreate = 'callablesDiscountsTemplatesCreate',
  callablesDiscountsTemplatesRemove = 'callablesDiscountsTemplatesRemove',
  callablesDiscountsTemplatesUpdate = 'callablesDiscountsTemplatesUpdate',
  callablesDiscountsApplicableCreate = 'callablesDiscountsApplicableCreate',
  callablesDiscountsApplicableRemove = 'callablesDiscountsApplicableRemove',
  callablesInvoiceProfileCreate = 'callablesInvoiceProfileCreate',
  callablesInvoiceProfileGet = 'callablesInvoiceProfileGet',
  callablesInvoiceProfileUpdate = 'callablesInvoiceProfileUpdate',
  callablesTransfersRecordCustomerCredit = 'callablesTransfersRecordCustomerCredit',
  callablesMessageTemplatesCreate = 'callablesMessageTemplatesCreate',
  callablesMessageTemplatesDelete = 'callablesMessageTemplatesDelete',
  callablesMessageTemplatesGet = 'callablesMessageTemplatesGet',
  callablesMessageTemplatesUpdate = 'callablesMessageTemplatesUpdate',
  callablesMessageNotificationsAssign = 'callablesMessageNotificationsAssign',
  callablesNextBestOfferTemplatesCreate = 'callablesNextBestOfferTemplatesCreate',
  callablesNextBestOfferTemplatesUpdate = 'callablesNextBestOfferTemplatesUpdate',
  callablesNextBestOfferTemplatesDelete = 'callablesNextBestOfferTemplatesDelete',
  callablesNextBestOfferTemplatesGetByEventId = 'callablesNextBestOfferTemplatesGetByEventId',
  callablesNextBestOffersCreate = 'callablesNextBestOffersCreate',
  callablesNextBestOffersDelete = 'callablesNextBestOffersDelete',
  callablesNextBestOfferGetByReservation = 'callablesNextBestOfferGetByReservation',
  callablesBrandProfileCreate = 'callablesBrandProfileCreate',
  callablesBrandProfileUpdate = 'callablesBrandProfileUpdate',
  callablesBrandProfileDelete = 'callablesBrandProfileDelete',
  callablesBrandProfileGet = 'callablesBrandProfileGet',
  callablesCustomerCardGetByUser = 'callablesCustomerCardGetByUser',
  callablesEventGetStatistics = 'callablesEventGetStatistics',
  callablesOrderItemsChangePrice = 'callablesOrderItemsChangePrice',
  callablesDiscountsAppliedChange = 'callablesDiscountsAppliedChange',
  callablesShopItemsGetForAdminEventData = 'callablesShopItemsGetForAdminEventData',
  callablesShopItemsUpdateUiIndex = 'callablesShopItemsUpdateUiIndex',
  callablesOrdersGetByUuid = 'callablesOrdersGetByUuid',
  callablesInvoicesGetByOrder = 'callablesInvoicesGetByOrder',
  callablesInvoicesUpdate = 'callablesInvoicesUpdate',
  callablesOrganizationsCheckValidIco = 'callablesOrganizationsCheckValidIco',
  callablesOrganizationsCheckVatPayer = 'callablesOrganizationsCheckVatPayer',
  callablesOrganizationsGetCompanyInfo = 'callablesOrganizationsGetCompanyInfo',
  callablesPromoCodesGetState = 'callablesPromoCodesGetState',
  callablesPromoCodesGetByEventId = 'callablesPromoCodesGetByEventId',
  callablesExportReservations = 'callablesExportReservations',
  callablesShopItemsFormSettingsMissingDataFields = 'callablesShopItemsFormSettingsMissingDataFields',
  callablesShopItemsFormSettingsUpsert = 'callablesShopItemsFormSettingsUpsert',
  callablesCollectedUserDataGetUnused = 'callablesCollectedUserDataGetUnused',
  callablesShopItemsFormSettingsAddMany = 'callablesShopItemsFormSettingsAddMany',
  callablesShopItemsFormSettingsRemove = 'callablesShopItemsFormSettingsRemove',
  callablesOffersUpdate = 'callablesOffersUpdate',
  callablesOffersIssue = 'callablesOffersIssue',
  callablesOffersGetOrgShopItems = 'callablesOffersGetOrgShopItems',
  callablesOffersGetOrgContactUsers = 'callablesOffersGetOrgContactUsers',
  callablesOffersGetOfferItems = 'callablesOffersGetOfferItems',
  callablesOffersGetByOrgId = 'callablesOffersGetByOrgId',
  callablesOffersGetById = 'callablesOffersGetById',
  callablesOffersAddOfferItems = 'callablesOffersAddOfferItems',
  callablesOffersAddContactUsers = 'callablesOffersAddContactUsers',
  callablesOffersUpdateSection = 'callablesOffersUpdateSection',
  callablesOffersCanModify = 'callablesOffersCanModify',
  callablesOffersRemoveOfferItems = 'callablesOffersRemoveOfferItems',
  callablesUsersGetByMagic = 'callablesUsersGetByMagic',
  callablesOfferItemUpdate = 'callablesOfferItemUpdate',
  callablesReservationsGetCustomData = 'callablesReservationsGetCustomData',
  callablesReservationsGetForCheckin = 'callablesReservationsGetForCheckin',
  callablesReservationsSetCheckedin = 'callablesReservationsSetCheckedin',
  callablesCollectedUserDataRemove = 'callablesCollectedUserDataRemove',
  callablesShopItemsFormSettingsUpdateIndices = 'callablesShopItemsFormSettingsUpdateIndices',
  callablesSessionGroupsCanChangeRegistrationType = 'callablesSessionGroupsCanChangeRegistrationType',
  callablesReservationsDeleteSysAdmin = 'callablesReservationsDeleteSysAdmin',
  callablesMagicCodesGetForCustomer = 'callablesMagicCodesGetForCustomer',
  callablesExportIsdocInvoice = 'callablesExportIsdocInvoice',
  callablesInvoicesCanModify = 'callablesInvoicesCanModify',
  callablesInvoicesModify = 'callablesInvoicesModify',
  callablesInvoicesGetManyByOrg = 'callablesInvoicesGetManyByOrg',
  callablesDiscountsTemplatesArchive = 'callablesDiscountsTemplatesArchive',
  callablesDiscountsApplicableIn = 'callablesDiscountsApplicableIn',
  callablesReservationsSendMassEmail = 'callablesReservationsSendMassEmail',
  callablesUserDataGet = 'callablesUserDataGet',
  callablesUserDataUpdate = 'callablesUserDataUpdate',
  callablesReservationsRestoreCancelled = 'callablesReservationsRestoreCancelled',
  callablesInvoicesCreate = 'callablesInvoicesCreate',
  callablesOrdersRestore = 'callablesOrdersRestore',
  callablesTransfersRemoveUnpaired = 'callablesTransfersRemoveUnpaired',
  callablesTransfersPair = 'callablesTransfersPair',
  callablesReservationsGetCancellableBeforeShopItemStateChange = 'callablesReservationsGetCancellableBeforeShopItemStateChange',
  callablesUsersChangeEmail = 'callablesUsersChangeEmail',
  callablesSessionAttendancesGetBySession = 'callablesSessionAttendancesGetBySession',
  callablesOffersCancel = 'callablesOffersCancel',
  callablesShopItemsUpsertInstallmentsSettings = 'callablesShopItemsUpsertInstallmentsSettings',
  callablesclosedLecturerAttendanceMonthIsClosed = 'callablesclosedLecturerAttendanceMonthIsClosed',
  callablesClosedLecturerAttendanceMonthToggle = 'callablesClosedLecturerAttendanceMonthToggle',
  callablesGetCustomerReservationFromDetails = 'callablesGetCustomerReservationFromDetails',
  callablesCustomersWhisper = 'callablesCustomersWhisper',
  callablesSessionGroupsLecturerGet = 'callablesSessionGroupsLecturerGet',
  callablesSessionAttendanceReplace = 'callablesSessionAttendanceReplace',
  callablesOrganizationsGetForSysAdmin = 'callablesOrganizationsGetForSysAdmin',
  callablesOrganizationsGetServiceFees = 'callablesOrganizationsGetServiceFees',
  callablesSessionsGetForCard = 'callablesSessionsGetForCard',
  callablesReservationsGetForDetail = 'callablesReservationsGetForDetail',
  callablesShopItemsGetForReservationShopItemChange = 'callablesShopItemsGetForReservationShopItemChange',
  callablesReservationsChangeShopItem = 'callablesReservationsChangeShopItem',
  callablesTicketsSend = 'callablesTicketsSend',
  callablesTicketsGetSingle = 'callablesTicketsGetSingle',
  callablesUsersGetForSendingTicket = 'callablesUsersGetForSendingTicket',
  callablesUsersGetCheckinStats = 'callablesUsersGetCheckinStats',
  callablesShopItemsGetDynamicPrice = 'callablesShopItemsGetDynamicPrice',
  callablesApiCreateStripeWebhook = 'callablesApiCreateStripeWebhook',
  callablesUsersCustomerGetOrgInternalCredit = 'callablesUsersCustomerGetOrgInternalCredit',
  callablesReservationsGetPriceSummary = 'callablesReservationsGetPriceSummary',
  callablesSessionsUpdate = 'callablesSessionsUpdate',
  callablesUsersCustomerGetPaymentData = 'callablesUsersCustomerGetPaymentData',
  callablesExportInvoicesPohoda = 'callablesExportInvoicesPohoda',
  callablesUsersUpdateAccount = 'callablesUsersUpdateAccount',
  callablesCustomersGetForOverview = 'callablesCustomersGetForOverview',
  callablesUtilsLanguageGet = 'callablesUtilsLanguageGet',
  callablesUsersCustomerEventCartSize = 'callablesUsersCustomerEventCartSize',
  callablesOrganizationsCreate = 'callablesOrganizationsCreate',
  callablesExportPayments = 'callablesExportPayments',
  callablesShopItemsGetForReservationsTableItemFilter = 'callablesShopItemsGetForReservationsTableItemFilter',
  callablesUtilsLogFeError = 'callablesUtilsLogFeError',
  callablesTransfersGetOrderCompletedPayments = 'callablesTransfersGetOrderCompletedPayments',
  callablesShopItemsGetPaymentApis = 'callablesShopItemsGetPaymentApis',
  generateShopItemCustomSettings = 'generateShopItemCustomSettings',
  calculateShopItemsVat = 'calculateShopItemsVat',
  callablesOrdersSetDates = 'callablesOrdersSetDates',
  callablesPaymentsGetComgateMethods = 'callablesPaymentsGetComgateMethods',
  callablesShopItemsGetShowPreview = 'callablesShopItemsGetShowPreview',
  callablesDiscountsTemplatesGetForShopItemSelect = 'callablesDiscountsTemplatesGetForShopItemSelect',
  callablesShopItemsSetApplicableDiscounts = 'callablesShopItemsSetApplicableDiscounts',
  callablesDiscountsTemplatesGetEventDiscounts = 'callablesDiscountsTemplatesGetEventDiscounts',
  callablesEventsGetForDuplication = 'callablesEventsGetForDuplication',
  callablesReservationsGetForCustomerOrderList = 'callablesReservationsGetForCustomerOrderList',
  callablesShopItemsSettingModulesDelete = 'callablesShopItemsSettingModulesDelete',
  callablesShopItemsSettingModulesCreate = 'callablesShopItemsSettingModulesCreate',
  callablesShopItemsSettingModulesUpdate = 'callablesShopItemsSettingModulesUpdate',
  callablesShopItemsSettingModulesUpdateUiIndexes = 'callablesShopItemsSettingModulesUpdateUiIndexes',
  fillShopItemModules = 'fillShopItemModules', // tmp
  callablesCollectedUserDataUpdate = 'callablesCollectedUserDataUpdate',
  callablesExportInvoicesOverview = 'callablesExportInvoicesOverview',
  callablesOrganizationsCustomDomainsUpdate = 'callablesOrganizationsCustomDomainsUpdate',
  callablesOrganizationsCustomDomainsVerify = 'callablesOrganizationsCustomDomainsVerify',
  callablesExportInvoicesMoneyS3 = 'callablesExportInvoicesMoneyS3',
  callablesLektoryApiCreateKey = 'callablesLektoryApiCreateKey',
  callablesEventsUnarchive = 'callablesEventsUnarchive',
  callablesItemNotesGetPagination = 'callablesItemNotesGetPagination',
  callablesItemNotesCreate = 'callablesItemNotesCreate',
  setInvoiceType = 'setInvoiceType', // tmp
  setInvoiceItems = 'setInvoiceItems', // tmp
  callablesEventsGetForDiscountTemplateDropdown = 'callablesEventsGetForDiscountTemplateDropdown',
  callablesShopItemsGetForDiscountTemplateSpecificShopitemsInput = 'callablesShopItemsGetForDiscountTemplateSpecificShopitemsInput',
  callablesAccreditationTemplatesCreate = 'callablesAccreditationTemplatesCreate',
  callablesAccreditationTemplatesUpdate = 'callablesAccreditationTemplatesUpdate',
  callablesAccreditationTemplatesDelete = 'callablesAccreditationTemplatesDelete',
  callablesAccreditationsSet = 'callablesAccreditationsSet',
  callablesAccreditationsDelete = 'callablesAccreditationsDelete',
  callablesAccreditationTemplatesGetForSiAccreditationModal = 'callablesAccreditationTemplatesGetForSiAccreditationModal',
  callablesAccreditationTemplatesIn = 'callablesAccreditationTemplatesIn',
  callablesInvoicesGetCreditNote = 'callablesInvoicesGetCreditNote',
  callablesCategoriesGetForSiCategoriesModal = 'callablesCategoriesGetForSiCategoriesModal',
  callablesCategoriesCreate = 'callablesCategoriesCreate',
  callablesCategoriesUpdate = 'callablesCategoriesUpdate',
  callablesCategoryGroupsGetAllByOrg = 'callablesCategoryGroupsGetAllByOrg',
  callablesShopItemsUpdateReservationType = 'callablesShopItemsUpdateReservationType',
  callablesShopItemsUpdatePublicationType = 'callablesShopItemsUpdatePublicationType',
  callablesCategoryGroupCreate = 'callablesCategoryGroupCreate',
  callablesCategoryGroupUpdate = 'callablesCategoryGroupUpdate',
  callablesCategoriesSetToShopItem = 'callablesCategoriesSetToShopItem',
  callablesCategoriesArchive = 'callablesCategoriesArchive',
  callablesItemNotesUpdate = 'callablesItemNotesUpdate',
  callablesItemNotesUpdateState = 'callablesItemNotesUpdateState',
  callablesItemNotesDelete = 'callablesItemNotesDelete',
  callablesCustomerNotesCreate = 'callablesCustomerNotesCreate',
  callablesCustomerNotesDelete = 'callablesCustomerNotesDelete',
  callablesCustomerNotesGetPagination = 'callablesCustomerNotesGetPagination',
  callablesCustomerNotesUpdate = 'callablesCustomerNotesUpdate',
  callablesCustomerNotesUpdateState = 'callablesCustomerNotesUpdateState',
  callablesMessagesGetForCustomerCard = 'callablesMessagesGetForCustomerCard',
  callablesMessagesGetUserTotalCount = 'callablesMessagesGetUserTotalCount',
  callablesAssertDiscounts = 'callablesAssertDiscounts',
  callablesReservationsConfirmAsGuest = 'callablesReservationsConfirmAsGuest',
  callablesShopItemsSgLecturersModalGet = 'callablesShopItemsSgLecturersModalGet',
  callablesShopItemsSgLecturersModalSet = 'callablesShopItemsSgLecturersModalSet',
  callablesShopItemsSiAdminStoreGet = 'callablesShopItemsSiAdminStoreGet',
  callablesSessionGroupsSiModuleSgLecturersRemove = 'callablesSessionGroupsSiModuleSgLecturersRemove',
  callablesSiModuleSessionsCreateSession = 'callablesSiModuleSessionsCreateSession',
  callablesSessionsArchive = 'callablesSessionsArchive',
  callablesSessionsCopy = 'callablesSessionsCopy',
  callablesSessionGroupLecturersUpdateHourRate = 'callablesSessionGroupLecturersUpdateHourRate',
  callablesShopItemsSiCoordinatorsModalGet = 'callablesShopItemsSiCoordinatorsModalGet',
  callablesShopItemsSiCoordinatorsModalSet = 'callablesShopItemsSiCoordinatorsModalSet',
  callablesSessionGroupsSiModuleCoordinatorRemove = 'callablesSessionGroupsSiModuleCoordinatorRemove',
  callablesInvoicesGetById = 'callablesInvoicesGetById',
  callablesEventsGetShowPreview = 'callablesEventsGetShowPreview',
  callablesUserPreferencesUpsert = 'callablesUserPreferencesUpsert',
  callablesUserPreferencesGetForUser = 'callablesUserPreferencesGetForUser',
  callablesCoordinatorGetForEventFilter = 'callablesCoordinatorGetForEventFilter',
  callablesOrganizationsMembersGetCustomFormSettings = 'callablesOrganizationsMembersGetCustomFormSettings',
  callablesOrganizationMembersGetOrgMemberData = 'callablesOrganizationMembersGetOrgMemberData',
  callablesScheduledTasksCancel = 'callablesScheduledTasksCancel',
  callablesUsersCustomerCreate = 'callablesUsersCustomerCreate',
  callablesLocationsGetForEventFilter = 'callablesLocationsGetForEventFilter',
  callablesSessionsGetForLocationPicker = 'callablesSessionsGetForLocationPicker',
  callablesShopItemsSettingModulesGetAvailable = 'callablesShopItemsSettingModulesGetAvailable',
  callablesShopItemsGetTemplates = 'callablesShopItemsGetTemplates',
  callablesShopItemsSaveAsTemplate = 'callablesShopItemsSaveAsTemplate',
  callablesShopItemsCreateInstance = 'callablesShopItemsCreateInstance',
  callablesShopItemsHasTemplates = 'callablesShopItemsHasTemplates',
  callablesMessageTemplatesUpsert = 'callablesMessageTemplatesUpsert',
  callablesUsersLecturerGetSessions = 'callablesUsersLecturerGetSessions',
  callablesOrganizationsHasEventOffers = 'callablesOrganizationsHasEventOffers',
  callablesEventsGetForAdminEventList = 'callablesEventsGetForAdminEventList',
  callablesEventsGetForAdminEventOfferList = 'callablesEventsGetForAdminEventOfferList',
  callablesShopItemsSetAsRepresentative = 'callablesShopItemsSetAsRepresentative',
  callablesShopItemsGetRepresentativesForNewSiModal = 'callablesShopItemsGetRepresentativesForNewSiModal',
  callablesEventsGetForChooseForInstanceModal = 'callablesEventsGetForChooseForInstanceModal',
  callablesShopItemsRemoveInstance = 'callablesShopItemsRemoveInstance',
  callablesCertificateTemplatesGetForSelectModal = 'callablesCertificateTemplatesGetForSelectModal',
  callablesCertificatesGetForSiAdminPage = 'callablesCertificatesGetForSiAdminPage',
  callablesReservationsGetForCertificateIssueModal = 'callablesReservationsGetForCertificateIssueModal',
  callablesCertificatesIssue = 'callablesCertificatesIssue',
  callablesReservationsGetCancellationSummary = 'callablesReservationsGetCancellationSummary',
  callablesSessionAttendancesGetForAdminShopItem = 'callablesSessionAttendancesGetForAdminShopItem',
  callablesCertificateTemplatesGetForEditor = 'callablesCertificateTemplatesGetForEditor',
  callablesCertificateTemplatesUpdateByEditor = 'callablesCertificateTemplatesUpdateByEditor',
  callablesCertificateTemplatesCreateByEditor = 'callablesCertificateTemplatesCreateByEditor',
  callablesPdfGenerate = 'callablesPdfGenerate',
  callablesSessionAttendanceGetPdf = 'callablesSessionAttendanceGetPdf',
  callablesAttendanceDocumentTemplatesGetForSessionAttendance = 'callablesAttendanceDocumentTemplatesGetForSessionAttendance',
  callablesAttendanceDocumentTemplatesGetForEditor = 'callablesAttendanceDocumentTemplatesGetForEditor',
  callablesAttendanceDocumentTemplatesUpdateByEditor = 'callablesAttendanceDocumentTemplatesUpdateByEditor',
  callablesAttendanceDocumentTemplatesCreateByEditor = 'callablesAttendanceDocumentTemplatesCreateByEditor',
  callablesReservationsTableGetCustomDataColumns = 'callablesReservationsTableGetCustomDataColumns',
  callablesPublicTagsGetForSiPublicTagsModal = 'callablesPublicTagsGetForSiPublicTagsModal',
  callablesPublicTagsCreate = 'callablesPublicTagsCreate',
  callablesPublicTagsUpdate = 'callablesPublicTagsUpdate',
  callablesPublicTagGroupsGetAllByOrg = 'callablesPublicTagGroupsGetAllByOrg',
  callablesPublicTagGroupCreate = 'callablesPublicTagGroupCreate',
  callablesPublicTagGroupUpdate = 'callablesPublicTagGroupUpdate',
  callablesPublicTagsSetToShopItem = 'callablesPublicTagsSetToShopItem',
  callablesPublicTagsArchive = 'callablesPublicTagsArchive',
  callablesCompaniesGetForOverview = 'callablesCompaniesGetForOverview',
  callablesPublicTagsGetForFilterItem = 'callablesPublicTagsGetForFilterItem',
  callablesCategoriesGetForFilterItem = 'callablesCategoriesGetForFilterItem',
  callablesPublicTagsGetForEventSiFilterItem = 'callablesPublicTagsGetForEventSiFilterItem',
  callablesInternalTagsGetForSiInternalTagsModal = 'callablesInternalTagsGetForSiInternalTagsModal',
  callablesInternalTagsCreate = 'callablesInternalTagsCreate',
  callablesInternalTagsUpdate = 'callablesInternalTagsUpdate',
  callablesInternalTagGroupsGetAllByOrg = 'callablesInternalTagGroupsGetAllByOrg',
  callablesInternalTagGroupCreate = 'callablesInternalTagGroupCreate',
  callablesInternalTagGroupUpdate = 'callablesInternalTagGroupUpdate',
  callablesInternalTagsSetToShopItem = 'callablesInternalTagsSetToShopItem',
  callablesInternalTagsArchive = 'callablesInternalTagsArchive',
  callablesInternalTagsGetForFilterItem = 'callablesInternalTagsGetForFilterItem',
  callablesInvoicesGetPlaceholderData = 'callablesInvoicesGetPlaceholderData',
  callablesInvoicesIssue = 'callablesInvoicesIssue',
  callablesDiscountsAppliedRemove = 'callablesDiscountsAppliedRemove',
  callablesDiscountsAppliedCount = 'callablesDiscountsAppliedCount',
  callablesBulkMessagesWhisperRecipients = 'callablesBulkMessagesWhisperRecipients',
  callablesBulkMessagesSend = 'callablesBulkMessagesSend',
  callablesBulkMessagesGetForOrg = 'callablesBulkMessagesGetForOrg',
  callablesBulkMessagesCanSend = 'callablesBulkMessagesCanSend',
  callablesReservationsUpdateSimpleAdminNote = 'callablesReservationsUpdateSimpleAdminNote',
  callablesShopItemsItemNumberGetPreview = 'callablesShopItemsItemNumberGetPreview',
  callablesShopItemsItemNumberSave = 'callablesShopItemsItemNumberSave',
  callablesExportEventIcs = 'callablesExportEventIcs',
  callablesShopItemsHasReservations = 'callablesShopItemsHasReservations',
  callablesReservationsDelete = 'callablesReservationsDelete',
  callablesCertificatesGetForView = 'callablesCertificatesGetForView'
}

interface IRunCallableData {
  name: CallableNames;
  data?: any;
  warmup?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    private appMessageService: AppMessageService,
    private ff: Functions
  ) {}

  public runCallable({ name, data, warmup = false }: IRunCallableData): Observable<unknown> {
    const obs = httpsCallableData(this.ff, 'callablesrun')({ name, data, warmup, currentUrl: location.href });
    return obs;
  }

  public handleObs(obs: Observable<any>, { succSilent = false, errSilent = false, allSilent = false}: { succSilent?: boolean, errSilent?: boolean, allSilent?: boolean } = {}, customSummary?: string) {
    return obs.pipe(
      map(res => {
        if (!succSilent && !allSilent) {
          this.appMessageService.successMessage(res, customSummary);
        }
        return res.data;
      }),
      catchError(err => {
        if (!errSilent && !allSilent) {
          this.appMessageService.errorMessage(err, customSummary);
        }
        console.error(err);
        console.error(err.code);
        throw err;
      })
    )
  }
}
